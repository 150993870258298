import React, { useEffect, useRef, useState } from "react";
import "../css/layout.css";
import css from "../css/Profile.module.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Rightcontainer from "../Components/Rightcontainer";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../css/kyc.css";
import "../css/Loader.css";
import { IoIosCall } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import { IoIosLogOut } from "react-icons/io";
import findGif from "../css/loading_old.gif";

const Profile1 = ({ profile1 }) => {
  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // alert(baseUrl)
  // +++++++++++++++++++++++++++++
  const [show, setShow] = useState(false);

  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow1(false);
  const handleShow = () => {
    setShow1(true);
  };

  const [referral, setCode] = useState("");
  const [Id, setId] = useState(null);

  const [profile, setProfile] = useState();
  const [portcss, setPortcss] = useState(css.active_tab);
  const [portcss1, setPortcss1] = useState(css.inactive_tab);
  const [crushcss, setCrushcss] = useState(true);
  const [holder_name, setHolder_name] = useState();
  const [account_number, setAccount_number] = useState();
  const [ifsc_code, setIfsc_code] = useState();
  const [upi_id, setUpi_id] = useState();
  const [isLoading, setIsloading] = useState(false);

  // KYC MODEL

  const [showKyc, setShowKyc] = useState(false);
  const [showBottom, setShowBottom] = useState(false);
  const [process1, setProcesss] = useState(false);

  const handleCloseBottom = () => setShowBottom(false);
  const handleShowBottom = (data) => {
    setShowBottom(true);
  };
  const handleCloseKyc = () => {
    setShowKyc(false);
  };
  const handleShowKyc = () => setShowKyc(true);
  const [inputOpen, setInputOpen] = useState(false);
  const [Email, setEmail] = useState();

  const toggleInput = () => {
    setInputOpen(!inputOpen);
  };

  const history = useHistory();
  // const logout = () => {
  //   let access_token = localStorage.getItem('token')
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`
  //   }
  //   axios
  //     .post(
  //       baseUrl+`logout`,
  //       {
  //         headers: headers
  //       },
  //       { headers }
  //     )
  //     .then(res => {
  //       localStorage.clear()
  //       localStorage.removeItem('token')
  //       history.push('/login')
  //       window.location.reload(true)
  //     })
  //     .catch(e => {
  //       // alert(e.msg)
  //     })
  // }
  useEffect(() => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setProfile(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  }, []);

  const logout = () => {
    // const access_token = localStorage.getItem("token");
    // if (!access_token) {
    //   return;
    // }

    // const headers = {
    //   Authorization: `Bearer ${access_token}`,
    // };

    // axios
    //   .post(baseUrl+"logout", {}, { headers })
    //   .then(() => {
    //     localStorage.clear();
    //     localStorage.removeItem('token')
    //     history.push("/login");
    //   })
    //   .catch((error) => {
    //     console.error("Logout failed:", error);
    //   });
    localStorage.clear();

    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {})
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  };

  //Update email

  const UpdateEmail = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          Email,
        },
        { headers }
      )
      .then((res) => {
        if (res.data == "User name already exist!") {
          Swal.fire({
            title: res.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setEmail(res.data);
          setInputOpen(false);
          // UserALL();
        }
      });
  };

  // name Update
  const [Name, setName] = useState();

  const handleNameChange = (e) => {
    const enteredName = e.target.value;

    if (enteredName.length <= 10) {
      setName(enteredName);
    } else {
      Swal.fire({
        title: "Name lessthen 10 characters)",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  // siteSetting
  const [isOnlineKyc, setOnKyc] = useState(false);
  const [isOfflineKyc, setOffKyc] = useState(false);

  useEffect(() => {
    const addsetting = localStorage.getItem("sitSetting");
    const addCaseSetting = JSON.parse(addsetting);
    if (addCaseSetting.isOfflineKyc) {
      setOffKyc(true);
    } else {
      setOnKyc(true);
    }
  }, []);

  const UpdateProfile = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          Name,
        },
        { headers }
      )
      .then((res) => {
        if (res.data == "User name already exist!") {
          Swal.fire({
            title: res.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setName(res.data);

          // UserALL();
        }
      });
  };
  const updateBankDetails = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          holder_name,
          account_number,
          ifsc_code,
          upi_id,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setShow((prev) => !prev);
          // UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "something went wrong";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  const update_RefCode = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          referral,
        },
        { headers }
      )
      .then((res) => {
        if (res.status == 200) {
          // UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "Invalid referral Code";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  //// total game
  const [total, setTotal] = useState();
  const TotalGame = (Id) => {
    let access_token = localStorage.getItem("token");
    let userId = localStorage.getItem("userId");

    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `total/user/all/${userId}`, {
        headers,
      })
      .then((res) => {
        setTotal(res.data);
      })
      .catch((e) => {});
  };

  const [pic, setPic] = useState();
  const Result = async (file) => {
    if (file) {
      const access_token = localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const formData = new FormData();
      formData.append("avatar", file);

      await fetch(baseUrl + `users/me/avatar`, {
        method: "POST",
        body: formData,
        headers,
      }).then((res) => {});
    }
  };
  //avatar

  const Kyc = async () => {
    setShowKyc(true);
    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          "Auto Kyc": "Auto Kyc",
          "Complete Kyc": "Complete Kyc",
        });
      }, 1000);
    });
  };

  // AADHAR API

  const [AadharNum, setAadharNum] = useState("");
  const [userAdharData, setUserAdharData] = useState("");
  // const handleAdharChnage = (e) => {
  //   setAadharNum(e.target.value);
  // };
  const handleAdharChnage = (e) => {
    const inputValue = e.target.value;

    if (/^[0-9]*$/.test(inputValue) || inputValue === "") {
      if (inputValue.length <= 12) {
        setAadharNum(inputValue);
      }
    }
  };
  const AdharNumber = (e) => {
    setIsloading(true);
    e.preventDefault();

    // Ensure AadharNum is a 12-digit number before making the API call
    if (/^[0-9]{12}$/.test(AadharNum)) {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const data = {
        aadharNumber: AadharNum,
      };

      axios
        .post(baseUrl + `aadharcard-auto`, data, {
          headers,
        })
        .then((res) => {
          setUserAdharData(res?.data);
          setIsloading(false);

          if (res.data.status === false) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.msg,
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Send OTP to your mobile number",
              text: res?.data?.data?.data?.message,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      Swal.fire({
        icon: "error",
        title: "Invalid Aadhar Number",
        text: "Please enter a valid 12-digit Aadhar number.",
      });
      setIsloading(false);
    }
  };

  const [AadharOtp, setAadharOtp] = useState("");
  const [verifyData, setVerifyData] = useState("");
  const [kycdata, setKycdata] = useState();
  const [isLoading1, setIsloading1] = useState(false);

  const handleAdharOtpChnage = (e) => {
    setAadharOtp(e.target.value);
  };

  const AdharOtp = (e) => {
    setIsloading1(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    const data = {
      aadharNumber: AadharNum,
      otp: AadharOtp,
      Email: Email,

      transaction_id: userAdharData?.data?.data?.transaction_id,
    };

    axios
      .post(
        baseUrl +
          `aadharcard-auto-verify
      `,
        data,
        {
          headers,
        }
      )
      .then((res) => {
        setKycdata(res.data);
        setVerifyData(res.data);
        setIsloading1(false);

        if (res.data.status === false) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.msg,
          }).then((result) => {
            if (result.isConfirmed) {
              handleCloseKyc();
            }
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Your Kyc successfully",
            text: res.data.msg,
          }).then((result) => {
            if (result.isConfirmed) {
              handleCloseKyc();
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // DOCUMENTATION API START

  let aadharProcess = useRef(false);

  const [Names, setNames] = useState("");
  const [DOB, setDob] = useState("");
  const [frontLoaded, setFrontLoaded] = useState(null);
  const [backLoaded, setBackLoaded] = useState(null);

  const handleSubmitdata = (e) => {
    e.preventDefault();

    if (!Names || !DOB || !frontLoaded || !backLoaded || !AadharNum) {
      alert("Please fill in all fields and upload both images.");
      return;
    }

    if (aadharProcess.current === false) {
      setProcesss(true);
      aadharProcess.current = true;

      const formData = new FormData();
      formData.append("Name", Names);
      formData.append("DOB", DOB);
      formData.append("front", frontLoaded[0]);
      formData.append("back", backLoaded[0]);
      formData.append("Number", AadharNum);
      formData.append("Email", Email);

      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      axios
        .post(baseUrl + `aadharcard`, formData, {
          headers,
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.status === false) {
            Swal.fire({
              title: res.data.msg,
              icon: "error",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                handleCloseKyc();
              }
            });
          } else if (res.data.msg === "Duplicate Entity") {
            Swal.fire({
              title: "Duplicate Entity",
              icon: "error",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                handleCloseKyc();
              }
            });
          } else {
            Swal.fire({
              title: "Your KYC form submitted",
              icon: "success",
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.isConfirmed) {
                handleCloseKyc();
              }
            });
            history.push("/Profile");
          }

          aadharProcess.current = false;
          setProcesss(false);
        })
        .catch((error) => {
          console.error(error);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("token");
            window.location.reload();
            history.push("/login");
          }
        });
    } else {
      alert("You have submitted a request already.");
    }
  };

  useEffect(() => {
    // UserALL();
    TotalGame();
  }, []);

  // Email validations
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const isValidFormat = emailRegex.test(newEmail);

    const endsWithCom = newEmail.toLowerCase().endsWith(".com");

    setIsValidEmail(isValidFormat && endsWithCom);
  };

  return (
    <>
      {/* <Header user={profile} /> */}
      {
        <div
          className="leftContainer mb_space"
          style={{
            minHeight: "100vh",
            paddingTop: "80px",
            paddingBottom: "60px",
          }}
        >
          <div className="profile_page">
            <div className=" mt-4 profile_div_main_box">
              <div>
                <label>
                  <input
                    className="d-none"
                    type="file"
                    onChange={(e) => Result(e.target.files[0])}
                    accept="image/*"
                    required
                  />
                  <picture>
                    {profile1 && profile1.avatar ? (
                      <img
                        height="80px"
                        width="80px"
                        src="/user.png"
                        alt=""
                        style={{ borderRadius: "50px" }}
                      />
                    ) : (
                      <img
                        height="80px"
                        width="80px"
                        src={process.env.PUBLIC_URL + `/user.png`}
                        alt=""
                        style={{ borderRadius: "50px" }}
                      />
                    )}
                  </picture>
                </label>
              </div>

              <div className="profile_details ">
                <h2>
                  {profile && profile.Name}
                  <img
                    className={`ml-2`}
                    width="20px"
                    src={process.env.PUBLIC_URL + "/images/icon-edit.jpg"}
                    alt=""
                    onClick={() => {
                      setPortcss(css.inactive_tab);
                      setPortcss1(css.active_tab);
                    }}
                  />
                </h2>
                <div className={`text-bold my-3 ${portcss1}`}>
                  <div
                    className={`${css.MuiFormControl_root} ${css.MuiTextField_root}`}
                    style={{ verticalAlign: "bottom" }}
                  >
                    <div
                      className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}
                    >
                      <input
                        aria-invalid="false"
                        type="text"
                        maxLength={"20"}
                        className={`${css.MuiInputBase_input} ${css.MuiInput_input}`}
                        placeholder="Enter Username"
                        value={Name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    width="20px"
                    src={
                      process.env.PUBLIC_URL +
                      "/images/select-blue-checkIcon.png"
                    }
                    alt=""
                    onClick={() => {
                      setPortcss(css.active_tab);
                      setPortcss1(css.inactive_tab);
                      UpdateProfile(Id);
                    }}
                  />
                </div>
                <h3>
                  <IoIosCall className="card_icons" />{" "}
                  {profile && profile?.Phone}
                </h3>
                {/* email */}
                <Link className={`d-flex align-items-center  email_verified`}>
                  <HiOutlineMail className="card_icons" />{" "}
                  <div className="Profile_mytext__ZlZJy">
                    {profile?.Email === null ? (
                      <div className="email_input">Email Verification</div>
                    ) : (
                      <h3>{profile?.Email}</h3>
                    )}
                  </div>
                  {profile?.Email === null ? (
                    <img
                      className={`ml-2`}
                      width="20px"
                      src="/images/icon-edit.jpg"
                      alt=""
                      onClick={toggleInput}
                    />
                  ) : profile?.Email !== null ? (
                    <span onClick={toggleInput}>
                      {" "}
                      <img
                        className={`ml-2`}
                        width="20px"
                        src="/images/icon-edit.jpg"
                        alt=""
                        onClick={toggleInput}
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </Link>

                <div
                  className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInput_underline} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}
                >
                  {inputOpen && (
                    <div>
                      <input
                        aria-invalid={!isValidEmail}
                        type="email"
                        className="email_input_inner"
                        placeholder="Enter your email"
                        value={Email}
                        onChange={handleEmailChange}
                      />

                      <img
                        className="ml-2 profile_name_edit"
                        width="20px"
                        src="/images/select-blue-checkIcon1.png"
                        alt=""
                        onClick={() => {
                          toggleInput();
                          if (isValidEmail) {
                            UpdateEmail(Id);
                          } else {
                            Swal.fire({
                              title: "please enter your email address",
                              icon: "error",
                              confirmButtonText: "OK",
                            });
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                {/* MODEL OF KYC  */}
                <Modal
                  show={showKyc}
                  onHide={handleCloseKyc}
                  className="Orher_page_main_section profile_page_tabs"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <ul
                    className="nav nav-tabs mb-4"
                    id="myTab"
                    role="tablist"
                    style={{ justifyContent: "center" }}
                  >
                    {Boolean(isOnlineKyc) && (
                      <li  className={`nav-item mr-2 ${Boolean(isOfflineKyc) ? '' : 'active'}`} role="presentation">
                        <button
                          className={`nav-link ${Boolean(isOfflineKyc) ? '' : 'active'} tabs`}
                          id="informative-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#informative"
                          type="button"
                          role="tab"
                          aria-controls="informative"
                          aria-selected={Boolean(isOfflineKyc) ? 'false' : 'true'}
                        >
                          Online KYC
                        </button>
                      </li>
                    )}
                    {Boolean(isOfflineKyc) && (
                      <li className={`nav-item ${Boolean(isOnlineKyc) ? '' : 'active'}`} role="presentation">
                        <button
                          className={`nav-link ${Boolean(isOnlineKyc) ? '' : 'active'}`}
                          id="game-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#game"
                          type="button"
                          role="tab"
                          aria-controls="game"
                          aria-selected={Boolean(isOnlineKyc) ? 'false' : 'true'}
                        >
                          Offline KYC
                        </button>
                      </li>
                    )}
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={`tab-pane fade ${Boolean(isOfflineKyc) ? '' : 'show active'}`}
                      id="informative"
                      role="tabpanel"
                      aria-labelledby="informative-tab"
                    >
                      {Boolean(isOnlineKyc) && (
                        <div className="input-group input-group-sm mb-3 ">
                          <label className="commaon_label">
                            Enter your Aadhar Number
                          </label>
                          <input
                            type="text"
                            className="commaon_input_box"
                            aria-describedby="basic-addon1"
                            value={AadharNum}
                            required
                            onChange={handleAdharChnage}
                          />
                          <label className="commaon_label">
                            Enter your Email
                          </label>
                          <input
                            type="email"
                            className="commaon_input_box"
                            aria-describedby="basic-addon1"
                            value={Email}
                            required
                            onChange={handleEmailChange}
                          />
                          {isLoading ? (
                            <div
                              className="pl-2 text-center d-flex"
                              style={{
                                justifyContent: "center",
                                width: "100%",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                src={findGif}
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  margin: "auto",
                                }}
                              />
                            </div>
                          ) : !userAdharData?.status === true ? (
                            <button
                              onClick={AdharNumber}
                              className="profile_wallet mt-2"
                            >
                              Send OTP
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      {userAdharData?.status === true ? (
                        <div className="input-group input-group-sm mb-3">
                          <label className="commaon_label">Enter OTP</label>
                          <input
                            type="number"
                            className="commaon_input_box"
                            aria-describedby="basic-addon1"
                            value={AadharOtp}
                            onChange={handleAdharOtpChnage}
                          />

                          {isLoading1 ? (
                            <div
                              className="pl-2 text-center d-flex"
                              style={{
                                justifyContent: "center",
                                width: "100%",
                                marginTop: "10px",
                              }}
                            >
                              <img
                                src={findGif}
                                style={{ width: "50px", height: "50px" }}
                                alt="Loading..."
                              />
                            </div>
                          ) : (
                            <div className="pl-2 text-center">
                              <button
                                onClick={AdharOtp}
                                className={`profile_wallet mt-2 ${
                                  isLoading1 ? "loading" : ""
                                }`}
                                disabled={isLoading1}
                              >
                                Submit OTP
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        " "
                      )}
                    </div>
                    {Boolean(isOfflineKyc) && (
                      <div
                      className={`tab-pane fade ${Boolean(isOnlineKyc) ? '' : 'show active'}`}
                        id="game"
                        role="tabpanel"
                        aria-labelledby="game-tab"
                      >
                        <div class="input-group input-group-sm mb-3">
                          <label className="commaon_label">Enter Name</label>
                          <input
                            type="text"
                            class="commaon_input_box"
                            value={Names}
                            onChange={(e) => setNames(e.target.value)}
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        </div>
                        <div class="input-group input-group-sm mb-3">
                          <label className="commaon_label">
                            Enter Aadhar Number
                          </label>
                          <input
                            type="text"
                            class="commaon_input_box"
                            value={AadharNum}
                            onChange={handleAdharChnage}
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        </div>
                        <div class="input-group input-group-sm mb-3">
                          <label className="commaon_label">
                            Enter your Email
                          </label>
                          <input
                            type="email"
                            class="commaon_input_box"
                            value={Email}
                            onChange={handleEmailChange}
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        </div>
                        <div class="input-group input-group-sm mb-3">
                          <label className="commaon_label">Enter DOB</label>
                          <input
                            type="date"
                            class="commaon_input_box"
                            value={DOB}
                            onChange={(e) => setDob(e.target.value)}
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        </div>
                        <div class="input-group input-group-sm mb-3">
                          <label className="commaon_label">Front Image</label>
                          <input
                            class="commaon_input_box"
                            id="frontPhoto"
                            name="frontPhoto"
                            type="file"
                            onChange={(e) => setFrontLoaded(e.target.files)}
                            accept="image/*"
                            required
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-sm"
                          />
                        </div>

                        <div class="input-group input-group-sm mb-3">
                          <label className="commaon_label">Back Image</label>
                          <input
                            id="backPhoto"
                            name="backPhoto"
                            type="file"
                            accept="image/*"
                            onChange={(e) => setBackLoaded(e.target.files)}
                            class="commaon_input_box"
                            aria-label="Sizing example input"
                            aria-describedby="inputGroup-sizing-sm"
                            required
                          />
                        </div>

                        <button
                          className="profile_wallet mt-2 mb-2"
                          onClick={handleSubmitdata}
                        >
                          submit
                        </button>
                      </div>
                   )} 
                  </div>
                  {isOfflineKyc || isOnlineKyc ? (
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseKyc}>
                        Close
                      </Button>
                    </Modal.Footer>
                  ) : (
                    <Modal.Footer>
                      <p style={{ color: "red" }}>
                        KYC process closed for some time.
                      </p>
                    </Modal.Footer>
                  )}
                </Modal>
              </div>
            </div>
          </div>

          <div className="Complete_Profile_box">
            <div className="new_kyc">
              {/* <h2 className="profile_heading">KYC STATUS</h2> */}

              <Link className={`d-flex align-items-center  complete_kyc_btn`}>
                <picture className="">
                  <img src="/images/kyc-icon-new.png" alt="" className="" />
                </picture>

                {profile && profile.verified === "verified" ? (
                  <div className={` ${css.mytext} `}>
                    <span onClick={handleShowBottom}>Kyc Verified ✅</span>
                  </div>
                ) : profile && profile.verified === "pending" ? (
                  <div className={` ${css.mytext} `}>In Process ⌚</div>
                ) : (
                  <div className={` ${css.mytext} `} onClick={Kyc}>
                    {profile && profile.verified === `unverified`
                      ? "KYC VERIFICATION"
                      : profile && profile.verified === "reject"
                      ? "KYC Rejeted ❌"
                      : "KYC VERIFICATION"}{" "}
                  </div>
                )}
              </Link>

              <Link
                to="/Addcase"
                className={`d-flex align-items-center  complete_kyc_btn`}
              >
                <picture className="">
                  <img
                    width="20px"
                    src={process.env.PUBLIC_URL + "/images/Header/wallet.png"}
                    alt=""
                    className=""
                  />
                </picture>
                <h2 className={` ${css.mytext}`}>Wallet</h2>
              </Link>
            </div>
            {/* <Link
              className={`d-flex align-items-center  complete_kyc_btn email_verified`}
            >
              <picture className="">
                <img
                  src="/images/LandingPage_img/emailgif.gif"
                  alt=""
                  className=""
                />
              </picture>
              <div className="Profile_mytext__ZlZJy">
                {profile?.Email === null ? (
                  <div className="email_input">Email Verification</div>
                ) : (
                  <div>{profile?.Email}</div>
                )}
              </div>
              {profile?.Email === null ? (
                <img
                  className={`ml-2`}
                  width="20px"
                  src="/images/icon-edit.jpg"
                  alt=""
                  onClick={toggleInput}
                />
              ) : profile?.Email !== null ? (
                <span onClick={toggleInput}>
                  {" "}
                  <img
                    className={`ml-2`}
                    width="35px"
                    src="/images/icon-edit.jpg"
                    alt=""
                    onClick={toggleInput}
                  />
                </span>
              ) : (
                ""
              )}
            </Link> */}
          </div>

          {/* MODEL KYC USER DETAIL */}
          {/* <Modal
            show={showBottom}
            onHide={handleCloseBottom}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="transaction_details_modal_body">
              <div className="bank_detail">
                <div className="d_flex">
                  <h2>Name :</h2>
                  <h2>DILKHUSH MEENA</h2>
                </div>
                <div className="d_flex">
                  <h2>Adhar Number:</h2>
                  <h2>125263456845</h2>
                </div>
                <div className="d_flex">
                  <h2>Gender</h2>
                  <h2>MALE</h2>
                </div>
                <div className="d_flex">
                  <h2>DOB</h2>
                  <h2>04/08/1998</h2>
                </div>
                <div className="d_flex">
                  <h2>Email</h2>
                  <h2>dilkhush002@gmail.com</h2>
                </div>
              </div>
              <div className="Upi_Detail">
                <div className="d_flex">
                  <h2>Address:</h2>
                  <h2>Lalsot</h2>
                </div>
              </div>
            </Modal.Body>
          </Modal> */}

          {profile && !profile.referral && (
            <div className="Complete_Profile_box border_top">
              <h2 className="profile_headings">Use Refer Code</h2>
              <div className={` ${css.position_relative}`}>
                <div className="user_reder_code_box">
                  <input
                    type="text"
                    className="commaon_input_box"
                    aria-describedby="basic-addon1"
                    value={referral}
                    onChange={(e) => {
                      setCode(e.target.value);
                    }}
                    placeholder="Enter Refer Code"
                  />
                  <button
                    className="check_btn"
                    style={{ backgroundColor: "transparent", border: "none" }}
                    onClick={() => {
                      update_RefCode();
                    }}
                  >
                    <img
                      width="20px"
                      className="ml-2"
                      src="/images/select-blue-checkIcon.png"
                    />
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* cash won small box */}
          <div className="profile_info_small_box_main_row">
            <h2 className="profile_headings">Other Details</h2>

            <div className="row rows">
              <div className="col-4 col-lg-4 col-md-4 col-sm-6">
                <div className="profile_info_small_box_main ">
                  <div className="profile_info_small_box_inner bg_1">
                    <span>₹</span>
                  </div>
                  <h3>Coin Won</h3>
                  <h4> {profile && profile.wonAmount}</h4>
                </div>
              </div>
              <div className="col-4 col-lg-4 col-md-4 col-sm-6">
                <div className="profile_info_small_box_main ">
                  <div className="profile_info_small_box_inner bg_3">
                    <img
                      src="/images/battle.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>Battle Played</h3>
                  <h4> {total && total}</h4>
                </div>
              </div>

              <div className="col-4 col-lg-4 col-md-4 col-sm-6">
                <div className="profile_info_small_box_main ">
                  <div className="profile_info_small_box_inner bg_2">
                    <img
                      src="/images/earning.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>Referral</h3>

                  <h4> {profile && profile.referral_earning}</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="logout_div">
            <Link
              to="/login"
              className={`${css.center_xy} `}
              onClick={(e) => logout(e)}
            >
              Log Out
            </Link>
          </div>
        </div>
      }

      <div className="rightContainer">
        <Rightcontainer />
      </div>
      {/* <ModalProfile style3={
                profileModalstyle
            } Enter={Enter}/> */}
      <div className={css.kyc_select} id="profileModal">
        <div className={css.overlay} />
        <div
          className={`${css.box}`}
          style={{
            bottom: "0px",
            position: "absolute",
          }}
        >
          <div className={css.bg_white}>
            <div className={`${css.header} ${css.cxy} ${css.flex_column}`}>
              <picture>
                <img
                  height="80px"
                  width="80px"
                  src="/images/avatars/Avatar2.png"
                  alt=""
                />
              </picture>
              <div className={`${css.header_text} mt-2`}>Choose Avatar</div>
            </div>
            <div className="mx-3 pb-3" style={{ paddingTop: "200px" }}>
              <div className="row justify-content-between col-10 mx-auto">
                <img
                  height="50px"
                  width="50px"
                  src="/images/avatars/Avatar1.png"
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src="/images/avatars/Avatar2.png"
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src="/images/avatars/Avatar3.png"
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src="/images/avatars/Avatar4.png"
                  alt=""
                />
              </div>
              <div className="row justify-content-between col-10 mx-auto mt-3">
                <img
                  height="50px"
                  width="50px"
                  src="/images/avatars/Avatar5.png"
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src="/images/avatars/Avatar6.png"
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src="/images/avatars/Avatar7.png"
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src="/images/avatars/Avatar8.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile1;

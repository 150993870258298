import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'
import Swal from "sweetalert2";

const SabpaiseGateway = (props) => {
  const [status, setStatus] = useState()
  const history = useHistory()
   // BASE URL
 
   const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
   const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
   const nodeMode = process.env.NODE_ENV;
   if (nodeMode === "development") {
 
     var baseUrl = beckendLocalApiUrl;
   } else {
     baseUrl = beckendLiveApiUrl;
 }
  const location = useLocation();
  const resutfromResponse = location?.state?.data;
  console.log("Payment Gateway Response ::", resutfromResponse);
  


  const decodedValue = queryStringToJSON(location?.state?.data)
console.log(decodedValue,'decodedValue')

useEffect(() => {


  if (decodedValue.status == "SUCCESS" || decodedValue.status == "FAILED") {
    let access_token = localStorage.getItem('token')
    access_token = localStorage.getItem('token')
    if (!access_token) {
      window.location.reload()
      history.push('/login')
    }
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
  
      axios
        .post(
          baseUrl+`subPaisa-txn-status`,
          decodedValue,
          { headers }
        )
        .then(res => {
         if(res.data.msg == 'pending'){
          history.push('/')
          setTimeout(() => {
            Swal.fire({
              title: 'Deposit is pending. will update after some time',
              icon: 'success',
              confirmButtonText: 'OK'
            })
          }, 1000)
         }else{
          const icon = res.data.msg == 'success' ? 'success' : 'danger'
          const title =
            res.data.msg == 'success'
              ? 'Deposit submited successfully'
              : 'Transaction Failed'
    
          history.push('/')
          setTimeout(() => {
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: 'OK'
            })
          }, 1000)
         }
         
        })
        .catch(e => {
          console.log(e.response,'e.e.e.')
          if (e.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('token')
            window.location.reload()
            history.push('/login')
          }
        })


  }else{

  }

}, [])

  function queryStringToJSON(qs) {
    qs = qs || location.search.slice(1);

    var pairs = qs.split('&');
    var result = {};
    pairs.forEach(function(p) {
        var pair = p.split('=');
        var key = pair[0];
        var value = decodeURIComponent(pair[1] || '');

        if( result[key] ) {
            if( Object.prototype.toString.call( result[key] ) === '[object Array]' ) {
                result[key].push( value );
            } else {
                result[key] = [ result[key], value ];
            }
        } else {
            result[key] = value;
        }
    });

    return JSON.parse(JSON.stringify(result));
};

   return (
    <>
      
    </>

)
}

export default SabpaiseGateway
